import React from 'react';
import NotificationContent from '../components/main/NotificationContent';

const NotificationPage = () => {
  return (
    <div className="pt-4 w-full sm:w-1/2 mx-auto">
      <NotificationContent />
    </div>
  );
};

export default NotificationPage;
