import React, { useState, useEffect } from 'react';
import { useBusiness } from '../../contexts/business';
import { FormProvider, useForm } from 'react-hook-form';
import Toggle from '../form/Toggle';
import Toggle2 from '../form/Toggle2';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TopMobileNavigation from '../main/navigation/TopMobileNavigation';
import { cleanText } from '../../utils/textValidater';
import PopoverPicker from '../main/color-picker/PopoverPicker';
import { BiCopy } from 'react-icons/bi';
import Modal2 from '../listing/Modal2';
// import { yupResolver } from '@hookform/resolvers/yup';
// import { UserFormSchema } from "../../../lib/yup"

import Input from '../form/Input';
import Select from '../form/Select';
import Textarea from '../form/Textarea';

import Loader from '../main/Loader';
import { AGENCY, DEVELOPER, CONSULTANCY } from '../../constants/constant';
import { AiOutlineUser, AiFillCamera, AiOutlineCheckCircle } from 'react-icons/ai';
import { ImageProfileUpload } from '../../utils/uploader';
import { RxCrossCircled } from 'react-icons/rx';
import LocationSearch from '../steps/propertySteps/Location/LocationSearch';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { useTranslation } from 'react-i18next';
import { validateEmail } from '../../utils/fieldValidater';

const copyCurrentUrl = (url: string) => {
  navigator.clipboard.writeText(url);
  toast.success('Link copied to clipboard!', {
    position: 'top-right',
    autoClose: 3000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  });
};

const ProfileForm: React.FC<any> = () => {
  const methods = useForm({
    mode: 'onTouched',
    // resolver: yupResolver(UserFormSchema),
  });

  const initialLocation = {
    city: '',
    country: '',
    latitude: 0,
    longitude: 0,
    postalCode: '',
  };

  const { currentBusiness, updateBusinessContext } = useBusiness();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [business, setBusiness] = useState(currentBusiness);

  const [isToggled, setIsToggled] = useState(currentBusiness?.isDedicatedProfile);
  const [isLoading, setIsLoading] = useState(false);
  const [showPhoneModal, setShowPhoneModal] = useState(false);

  const [isEditingBusinessName, setIsEditingBusinessName] = useState(false);
  const [isEditingLocation, setIsEditingLocation] = useState(false);
  const [isEditingStreetNameHouseNumber, setIsEditingStreetNameHouseNumber] = useState(false);
  const [isEditingWebsiteUrl, setIsEditingWebsiteUrl] = useState(false);
  const [isEditingBusinessCategory, setIsEditingBusinessCategory] = useState(false);

  const [isEditingEmail, setIsEditingEmail] = useState(false);
  const [isEditingPhoneNumber, setIsEditingPhoneNumber] = useState(false);

  const [isEditingDescription, setIsEditingDescription] = useState(false);

  const [locationObject, setLocationObject] = useState(initialLocation);
  const [locationString, setLocationString] = useState('');
  const [isEmailCorrect, setIsEmailCorrect] = useState(true);

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (event.key === 'Enter') {
      //....
    }
  };

  // handle change
  const handleBusinessNameChange = (event: any) => {
    setBusiness({ ...business, businessName: event.target.value });
  };

  const handleBusinessCategoryChange = (event: any) => {
    setBusiness({ ...business, businessCategory: event.target.value });
  };

  const handleEmailChange = (event: any) => {
    setBusiness({ ...business, email: event.target.value });
  };

  const handlePhoneNumberChange = (phone: any) => {
    setBusiness({ ...business, phone });
  };

  const handleDescriptionChange = (event: any) => {
    const cleanedDescription = cleanText(event.target.value);
    setBusiness({ ...business, description: cleanedDescription });
  };

  const handleStreetNameHouseNumberChange = (event: any) => {
    setBusiness({ ...business, streetNameHouseNumber: event.target.value });
  };

  const handleWebsiteUrlChange = (event: any) => {
    setBusiness({ ...business, websiteUrl: event.target.value });
  };

  // handle edit click
  const handleBusinessNameEditClick = () => {
    if (isEditingBusinessName) {
      updateBusinessContext({ businessName: business.businessName });
    }
    setIsEditingBusinessName((p) => !p);
  };

  const handleBusinessCategoryEditClick = () => {
    if (isEditingBusinessCategory) {
      updateBusinessContext({ businessCategory: business.businessCategory });
    }
    setIsEditingBusinessCategory((p) => !p);
  };

  const handleWebsiteUrlEditClick = () => {
    if (isEditingWebsiteUrl) {
      updateBusinessContext({ websiteUrl: business.websiteUrl });
    }
    setIsEditingWebsiteUrl((p) => !p);
  };

  const handleLocationEditClick = () => {
    console.log(isEditingLocation);

    const { city, postalCode, country, latitude, longitude } = locationObject;
    // if it is closed, we open
    if (!isEditingLocation) {
      setIsEditingLocation(true);
      // else we check if we can close/save
    } else {
      if (city === '' || postalCode === '' || country === '' || locationString === '') {
        setIsEditingLocation(true);
      } else {
        updateBusinessContext({
          city,
          postalCode,
          latitude,
          longitude,
          isoCodeCountry: country,
          location: locationString,
        });
        setIsEditingLocation(false);
      }
    }
  };

  const handleStreetNameHouseNumberEditClick = () => {
    if (isEditingStreetNameHouseNumber) {
      updateBusinessContext({ streetNameHouseNumber: business.streetNameHouseNumber });
    }
    setIsEditingStreetNameHouseNumber((p) => !p);
  };

  const handleEmailEditClick = () => {
    if (validateEmail(business.email)) {
      setIsEmailCorrect(true);
    } else {
      setIsEmailCorrect(false);
      return;
    }

    if (business.email !== currentBusiness?.email) {
      let confirmation = confirm('Are you sure you want to change the business email address?');

      if (confirmation) {
        if (isEditingEmail) {
          updateBusinessContext({ email: business.email });
        }
      } else {
        setBusiness({ ...business, email: currentBusiness?.email! });
      }
    }

    setIsEditingEmail((p) => !p);
  };

  const handlePhoneNumberEditClick = () => {
    if (isEditingPhoneNumber) {
      updateBusinessContext({ phone: business.phone });
    }
    setIsEditingPhoneNumber((p) => !p);
  };

  const handleDescriptionEditClick = () => {
    if (isEditingDescription) {
      updateBusinessContext({ description: business.description });
    }
    setIsEditingDescription((p) => !p);
  };

  const handleTogglePublicPhone = (checked: boolean) => {
    setBusiness((prevBusiness) => ({
      ...prevBusiness,
      isPhonePublic: checked,
    }));
    updateBusinessContext({ isPhonePublic: checked });
    setShowPhoneModal(false);
  };

  const onImageChange = (event: any) => {
    const tempImages = event.target.files;

    if (tempImages && tempImages[0]) {
      if (tempImages[0].size > 2097152) {
        toast.error('File too big, the maximum allowed size is 2MB');
        return;
      }

      if (tempImages[0]) {
        setIsLoading(true);
        ImageProfileUpload(tempImages[0], (progress: number) => {
          console.log(progress);
        })
          .then((res) => {
            const { secureUrl } = res;
            updateBusinessContext({ image: secureUrl });
            setBusiness({ ...business, image: secureUrl });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
    }
  };

  const handleKeyDown = (event: any) => {
    // if (event.key === 'Enter')
    if (event.keyCode == 13 && !event.shiftKey) {
      if (isEditingBusinessName) {
        handleBusinessNameEditClick();
        return;
      }
      if (isEditingBusinessCategory) {
        handleBusinessCategoryEditClick();
        return;
      }
      if (isEditingEmail) {
        handleEmailEditClick();
        return;
      }
      if (isEditingWebsiteUrl) {
        handleWebsiteUrlEditClick();
        return;
      }
      if (isEditingLocation) {
        handleLocationEditClick();
        return;
      }
      if (isEditingPhoneNumber) {
        handlePhoneNumberEditClick();
        return;
      }
      if (isEditingDescription) {
        handleDescriptionEditClick();
        return;
      }
      if (isEditingStreetNameHouseNumber) {
        handleStreetNameHouseNumberEditClick();
        return;
      }
    }
  };

  // use the current business
  useEffect(() => {
    //initialization
    if (currentBusiness.businessId) {
      setBusiness(currentBusiness);
      setLocationObject({
        city: currentBusiness.city,
        country: currentBusiness.country,
        latitude: currentBusiness.latitude,
        longitude: currentBusiness.longitude,
        postalCode: currentBusiness.postalCode,
      });
      setLocationString(currentBusiness.location);
      setIsToggled(currentBusiness.isDedicatedProfile);
    }
  }, [currentBusiness.businessId]);

  useEffect(() => {
    if (isToggled) {
      updateBusinessContext({ isDedicatedProfile: isToggled });
    }
  }, [isToggled]);

  useEffect(() => {
    setBusiness({ ...business, location: locationString });
  }, [locationString]);

  return (
    <>
      <TopMobileNavigation />
      <div className="flex flex-col pt-4 sm:pt-6 pb-20 text-sm mx-auto px-6 md:px-20">
        <div className=" font-bold text-3xl sm:text-5xl pb-3">{t('Business info')}</div>
        <div className="text-sm sm:text-xl">{t('Tell the world more about your business.')}</div>
        <div className="flex-col md:flex-row flex mt-12">
          <div className="w-full md:w-1/3 items-center flex-col flex space-y-4">
            <div
              title={t('edit profile image')}
              className="border-4  text-deehiy border-deehiy w-36 h-36 rounded-full"
            >
              <label htmlFor="file-input">
                {business.image ? (
                  <img
                    src={business.image}
                    alt="profile"
                    className="rounded-full aspect-square w-full "
                  />
                ) : (
                  <AiOutlineUser className="w-full h-full cursor-pointer hover:scale-105 duration-300 hover:shadow-md rounded-full " />
                )}

                <AiFillCamera className="relative top-[-36px] left-[106px] p-1 w-7 h-7 cursor-pointer bg-white rounded-full " />
              </label>
              <input className="hidden" id="file-input" type="file" onChange={onImageChange} />
            </div>
            <button
              type="button"
              className="font-bold text-deehiy"
              onClick={() => navigate('/users/verifyprofile')}
            >
              {t('Verify profile')}
            </button>
            {isLoading && <Loader />}
          </div>

          <div className=" flex-col w-full">
            <FormProvider {...methods}>
              <form
                className="flex-col space-y-4 "
                onKeyDown={handleKeyDown}
                onSubmit={handleSubmit}
              >
                <div className="w-full md:w-2/3">
                  <div className="pt-2 pb-4">
                    <div className="flex justify-between pb-2">
                      <span>{t('business name')}</span>
                      <button
                        type="button"
                        className="text-deehiy font-bold"
                        onClick={handleBusinessNameEditClick}
                      >
                        {!isEditingBusinessName ? t('Edit') : t('Save')}
                      </button>
                    </div>
                    <div>
                      {!isEditingBusinessName ? (
                        <div className="font-bold">{business.businessName}</div>
                      ) : (
                        <Input
                          label=""
                          id="username"
                          type="text"
                          value={business.businessName}
                          onChange={handleBusinessNameChange}
                        />
                      )}
                    </div>
                  </div>
                  <div className="pt-2 pb-4">
                    <div className="flex justify-between pb-2">
                      <span>{t('business category')}</span>
                      <button
                        type="button"
                        className="text-deehiy font-bold"
                        onClick={handleBusinessCategoryEditClick}
                      >
                        {!isEditingBusinessCategory ? t('Edit') : t('Save')}
                      </button>
                    </div>
                    <div>
                      {!isEditingBusinessCategory ? (
                        <div className="font-bold">{t(business.businessCategory)}</div>
                      ) : (
                        <Select
                          id="businessCategory"
                          name="businessCategory"
                          label=""
                          placeholder={t('select a category')}
                          onChange={handleBusinessCategoryChange}
                          value={business.businessCategory}
                        >
                          <option value={AGENCY} key={AGENCY}>
                            {t(AGENCY)}
                          </option>
                          <option value={DEVELOPER} key={DEVELOPER}>
                            {t(DEVELOPER)}
                          </option>
                          <option value={CONSULTANCY} key={CONSULTANCY}>
                            {t(CONSULTANCY)}
                          </option>
                        </Select>
                      )}
                    </div>
                  </div>
                  <div className="pt-2 pb-4">
                    <div className="flex justify-between pb-2">
                      <span>{t('email address')}</span>
                      <button
                        type="button"
                        className="text-deehiy font-bold"
                        onClick={handleEmailEditClick}
                      >
                        {!isEditingEmail ? t('Edit') : t('Save')}
                      </button>
                    </div>

                    <div>
                      {!isEditingEmail ? (
                        <div className="font-bold">{business.email}</div>
                      ) : (
                        <Input
                          label=""
                          id="email"
                          type="email"
                          value={business.email}
                          onChange={handleEmailChange}
                        />
                      )}
                    </div>
                    <span
                      className={`${
                        !isEmailCorrect && business.email.length > 8 ? 'text-red-400 ' : 'hidden'
                      } text-xs flex`}
                    >
                      Enter an email like: name@example.com
                    </span>
                  </div>
                  <div className="pt-2 pb-4">
                    <div className="flex justify-between pb-2">
                      <span>{t('location')}</span>
                      <button
                        type="button"
                        className="text-deehiy font-bold"
                        onClick={handleLocationEditClick}
                      >
                        {!isEditingLocation ? t('Edit') : t('Save')}
                      </button>
                    </div>
                    <div>
                      {!isEditingLocation ? (
                        <div className="font-bold">{business.location}</div>
                      ) : (
                        <div>
                          <LocationSearch
                            locationString={business.location}
                            setLocationString={setLocationString}
                            setLocationObject={setLocationObject}
                            label=""
                          />
                          <div
                            className={`flex flex-col duration-700 text-sm pl-2 py-1 bg-gray-100 ${
                              locationObject.country ? 'h-18' : ' hidden h-0'
                            }`}
                          >
                            <span className="grid grid-cols-2">
                              {t('country')}:
                              {locationObject.country ? (
                                <AiOutlineCheckCircle className="text-deehiy w-5 h-5" />
                              ) : (
                                <RxCrossCircled className="text-deehiy-red animate-pulse w-5 h-5" />
                              )}
                            </span>
                            <span className="grid grid-cols-2">
                              {t('city')}:
                              {locationObject.city ? (
                                <AiOutlineCheckCircle className="text-deehiy w-5 h-5" />
                              ) : (
                                <RxCrossCircled className="text-deehiy-red animate-pulse w-5 h-5" />
                              )}
                            </span>
                            <span className="grid grid-cols-2">
                              {t('postal Code')}:
                              {locationObject.postalCode ? (
                                <AiOutlineCheckCircle className="text-deehiy w-5 h-5" />
                              ) : (
                                <RxCrossCircled className="text-deehiy-red animate-pulse w-5 h-5" />
                              )}
                            </span>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="pt-2 pb-4">
                    <div className="flex justify-between pb-2">
                      <span>{t('street name, house number')} </span>
                      <button
                        type="button"
                        className="text-deehiy font-bold"
                        onClick={handleStreetNameHouseNumberEditClick}
                      >
                        {!isEditingStreetNameHouseNumber ? t('Edit') : t('Save')}
                      </button>
                    </div>
                    <div>
                      {!isEditingStreetNameHouseNumber ? (
                        <div className="font-bold">{business.streetNameHouseNumber}</div>
                      ) : (
                        <Input
                          label=""
                          id="streetNameHouseNumber"
                          type="text"
                          value={business.streetNameHouseNumber}
                          onChange={handleStreetNameHouseNumberChange}
                        />
                      )}
                    </div>
                  </div>
                  <div className="pt-2 pb-4">
                    <div className="flex justify-between pb-2">
                      <span>{t('website url')} </span>
                      <button
                        type="button"
                        className="text-deehiy font-bold"
                        onClick={handleWebsiteUrlEditClick}
                      >
                        {!isEditingWebsiteUrl ? t('Edit') : t('Save')}
                      </button>
                    </div>
                    <div>
                      {!isEditingWebsiteUrl ? (
                        <div className="font-bold">{business.websiteUrl}</div>
                      ) : (
                        <Input
                          label=""
                          id="websiteUrl"
                          type="text"
                          value={business.websiteUrl}
                          onChange={handleWebsiteUrlChange}
                        />
                      )}
                    </div>
                  </div>
                  <div className="pt-2 pb-4">
                    <div className="flex justify-between pb-2">
                      <span>{t('contact number')}</span>
                      <button
                        type="button"
                        className="text-deehiy font-bold"
                        onClick={handlePhoneNumberEditClick}
                      >
                        {!isEditingPhoneNumber ? t('Edit') : t('Save')}
                      </button>
                    </div>
                    <div>
                      {!isEditingPhoneNumber ? (
                        <PhoneInput
                          country={'th'}
                          enableSearch={false}
                          countryCodeEditable={false}
                          value={business.phone}
                          onChange={handlePhoneNumberChange}
                          buttonClass={'hover:!p-0 !border-none'}
                          inputClass={
                            '!w-full !py-0 !border-none !bg-transparent !text-base !font-normal text-defaultText'
                          }
                          searchClass={''}
                          disabled={true}
                        />
                      ) : (
                        <PhoneInput
                          country={'th'}
                          enableSearch={true}
                          countryCodeEditable={false}
                          placeholder={t('Enter phone number')}
                          value={business.phone}
                          onChange={handlePhoneNumberChange}
                          buttonClass={'hover:!p-0 !border-none'}
                          inputClass={
                            '!w-full !text-lg !py-5 !ring-2 ring-gray-200 !border-none focus:!ring-deehiy '
                          }
                          searchClass={'focus:ring-deehiy '}
                        />
                      )}
                    </div>
                  </div>

                  <div className=" w-full flex-col pb-8">
                    <div className="font-bold pb-2 whitespace-nowrap justify-between flex">
                      <span className="">publish phone number</span>
                      <div className="flex justify-end w-full">
                        <Toggle2
                          isToggled={business.isPhonePublic}
                          setIsToggled={(e: any) => {
                            if (!business.isPhonePublic) {
                              setShowPhoneModal(true);
                            } else {
                              handleTogglePublicPhone(e.target.checked);
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="text-xs sm:text-sm pr-8">
                      By publishing a phone number, I agree to the conditions, acknowledging
                      potential exposure to spam or fraudulent activities.{' '}
                      <a href="/help/article/1122" className="text-deehiy font-bold">
                        Learn More
                      </a>
                    </div>
                  </div>
                </div>

                <div className="pt-2 pb-4">
                  <div className="flex justify-between pb-2 w-full md:w-2/3">
                    <span>{t('tell the world about your business')}</span>
                    <button
                      type="button"
                      className="text-deehiy font-bold"
                      onClick={handleDescriptionEditClick}
                    >
                      {!isEditingDescription ? t('Edit') : t('Save')}
                    </button>
                  </div>
                </div>

                <div className="flex w-full">
                  {!isEditingDescription ? (
                    <div className="font-bold">{business.description}</div>
                  ) : (
                    <Textarea
                      cols={5}
                      maxLength={3000}
                      textLength={business.description?.length!}
                      rows={7}
                      placeholder=""
                      label=""
                      id="description"
                      value={business.description}
                      onChange={handleDescriptionChange}
                    />
                  )}
                </div>

                <div>
                  <div className="flex  w-full sm:w-2/3  flex-col pb-6">
                    <div className="font-bold pb-2 whitespace-nowrap justify-between flex">
                      {t('Dedicate profile as an agency')}
                      <div className="">
                        <Toggle
                          isToggled={currentBusiness?.isDedicatedProfile}
                          setIsToggled={setIsToggled}
                        />
                      </div>
                    </div>
                    <div className="text-xs">{t('text_dedicated_prof')}</div>
                  </div>
                </div>
                <div className="flex pt-2 w-full sm:w-2/3 justify-between">
                  <div className="text-sm  flex flex-col w-full ">
                    <span className="whitespace-nowrap font-bold  ">
                      &nbsp;{t('Enable your White Label Market')}
                    </span>{' '}
                  </div>
                  <div>
                    <Toggle2
                      isToggled={business.isWhiteLabel}
                      setIsToggled={() => {
                        updateBusinessContext({ isWhiteLabel: !business.isWhiteLabel });
                        setBusiness({ ...business, isWhiteLabel: !business.isWhiteLabel });
                      }}
                    />
                  </div>
                </div>
                <div className="text-deehiy semibold w-full sm:w-2/3 flex px-1 py-2 h-6 items-center">
                  {business.isWhiteLabel && (
                    <div className="flex items-center w-full">
                      <div
                        className="cursor-pointer flex items-center "
                        title="Open market"
                        onClick={() => {
                          window.open(
                            `${process.env.REACT_APP_BASE_URL_PROD}/business/${business.businessId}/market`,
                            '_blank'
                          );
                        }}
                      >
                        <div className="text-xs sm:text-sm flex line-clamp-1">
                          {process.env.REACT_APP_BASE_URL_PROD}/business/{business.businessId}
                          /market
                        </div>
                      </div>

                      <BiCopy
                        className=" w-5 h-5 shadow text-xs text-gray-400 cursor-pointer ml-2 hover:text-deehiy"
                        onClick={() =>
                          copyCurrentUrl(
                            `${process.env.REACT_APP_BASE_URL_PROD}/api/dl/market/${business.businessId}`
                          )
                        }
                      />
                    </div>
                  )}
                </div>

                <div className="text-sm pt-1 flex gap-x-2 items-center">
                  <span>{t('Choose your primary brand color')}</span>
                  <PopoverPicker
                    color={business.color}
                    onChange={(col) => {
                      updateBusinessContext({ color: col });
                      setBusiness({ ...business, color: col });
                    }}
                  />
                  <span className="w-24 italic text-gray-400">{business.color}</span>
                </div>
              </form>
              <ToastContainer transition={Slide} />
            </FormProvider>
          </div>
        </div>
        {showPhoneModal && (
          <div className="absolute top-0 left-0 w-full h-full">
            <Modal2 showModal={showPhoneModal} setShowModal={setShowPhoneModal}>
              <div className="p-10 flex space-y-6 flex-col items-center justify-center">
                <div className="font-bold text-2xl sm:text-5xl">WHOA!</div>
                <div className="semibold flex flex-col text-center text-base sm:text-lg">
                  <span>You are about to publish your phone number!</span>
                  <span>Are you sure this is what you want?</span>
                </div>

                <div className="justify-around flex w-full py-8">
                  <button
                    onClick={() => setShowPhoneModal(false)}
                    className="primary-btn ml-0 mr-0"
                  >
                    Take me back
                  </button>
                  <button
                    onClick={() => handleTogglePublicPhone(true)}
                    className="font-bold border-none outline-none hover:opacity-90"
                  >
                    Yes! Publish
                  </button>
                </div>
              </div>
            </Modal2>
          </div>
        )}
      </div>
      <ToastContainer />
    </>
  );
};

export default ProfileForm;
