import React, { useState, useEffect } from 'react';
import {
  getLeadsByUserIdService,
  // submitAllMatchesService,
} from '../../../../services/huntService';
import Loader from '../../../main/Loader';
import { useAuth } from '../../../../contexts/auth';
// import { useNavigate } from 'react-router-dom';
import LeadTable from '../table/LeadTable';
// import { Match } from '../../../../types/types';
import { useTranslation } from 'react-i18next';

const ListingLeadsContent = () => {
  const { currentUser } = useAuth();
  // const navigate = useNavigate();
  const { t } = useTranslation();
  const [hunts, setHunts] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  // const [showSubmitAllBtn, setShowSubmitAllBtn] = useState(true);
  // const [numberOfNewMatches, setNumberOfNewMatches] = useState(0);

  useEffect(() => {
    setIsLoading(true);

    getLeadsByUserIdService(currentUser?.id)
      .then((fetched) => {
        console.log(fetched.data);
        setHunts(fetched.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentUser]);

  // const handleSubmitAll = () => {
  //   setIsLoading(true);
  //   submitAllMatchesService(currentUser?.id)
  //     .then(() => {
  //       navigate('/users/leadsubmitted');
  //     })
  //     .catch((err: any) => {
  //       alert(`something went wrong:${err}`);
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  // useEffect(() => {
  //   if (hunts.length > 0) {
  //     const toSubmit = matches.filter((match: Match) => match.isSubmitted !== true);

  //     if (toSubmit.length > 0) {
  //       setNumberOfNewMatches(toSubmit.length);
  //     } else {
  //     }
  //   }
  // }, [matches]);

  if (hunts.length === 0) return <>{t('no leads found')}</>;

  return (
    <div className="w-full">
      {isLoading && <Loader />}
      <div className="font-bold flex justify-between my-4 items-center">
        {/* <span>{t('new_leads_message', { count: numberOfNewMatches })}</span> */}
        {/* {showSubmitAllBtn && (
          <div>
            <button
              onClick={handleSubmitAll}
              // className="mr-4 rounded-full px-2 py-1 sm:px-4 sm:py-2 text-xs sm:text-md bg-white border border-deehiy text-deehiy font-bold hover:opacity-90 shadow-md duration-200 whitespace-nowrap"
              className="secondary-btn"
            >
              {t('Submit all')}
            </button>
          </div>
        )} */}
      </div>
      <LeadTable />
    </div>
  );
};

export default ListingLeadsContent;
