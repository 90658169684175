import React, { useState, useMemo } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from '@tanstack/react-table';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { BsChevronExpand } from 'react-icons/bs';
import { Hunt } from '../../../../types/types';
import { adjustImageSize } from '../../../../utils/adjustImageSize';
import { formatDate } from '../../../../utils/dateFormatter';
import { useTranslation } from 'react-i18next';
// import { NumericFormat } from 'react-number-format';

const columnHelper = createColumnHelper<Hunt>();

const LeadMatchTable = ({ matches }: any) => {
  // const rerender = useReducer(() => ({}), {})[1];
  const { t } = useTranslation();
  const [sorting, setSorting] = useState<SortingState>([]);
  const data = matches;
  console.log('DATA', data);
  const columns = useMemo<ColumnDef<Hunt, any>[]>(() => {
    const baseColumns = [
      columnHelper.accessor((row) => row.property.image, {
        id: 'image',
        header: () => <span className="text-sm ">{t('Listings')}</span>,
        cell: (info) => (
          <img
            src={adjustImageSize(info.renderValue(), 150)}
            alt=""
            className="my-2 sm:my-3 pr-0 max-w-[50px] sm:max-w-[150px] aspect-video cursor-pointer"
            onClick={() =>
              window.open(`/properties/${info.row.original.property.propertyId}`, '_blank')
            }
          />
        ),
      }),
      columnHelper.accessor((row) => row.property.propertyTitle, {
        id: 'propertyTitle',
        cell: (info) => (
          <div
            title="open listing page"
            className="text-sm line-clamp-2 font-bold text-left cursor-pointer hover:underline"
            onClick={() =>
              window.open(`/properties/${info.row.original.property.propertyId}`, '_blank')
            }
          >
            {info.getValue()}
          </div>
        ),
        enableSorting: false,
        header: '',
      }),
      // columnHelper.accessor((row) => row.isForRent, {
      //   id: 'rentPrice',
      //   header: () => <span className="text-sm">{t('Rent price')}</span>,
      //   cell: (info) => (
      //     <div title="rent price" className="text-sm text-center ">
      //       {info.row.original.isForRent ? (
      //         <>
      //           <NumericFormat
      //             value={info.row.original.monthlyRentPrice}
      //             thousandSeparator=" "
      //             displayType={'text'}
      //             prefix={info.row.original.isoCodeCurrency + ' '}
      //           />
      //           ) / mo
      //         </>
      //       ) : (
      //         '-'
      //       )}
      //     </div>
      //   ),
      //   enableSorting: false,
      // }),
      // columnHelper.accessor((row) => row.isForSale, {
      //   id: 'salePrice',
      //   header: () => <span className="text-sm">{t('Sale price')}</span>,
      //   cell: (info) => (
      //     <div title="sale price" className="text-sm text-center">
      //       {info.row.original.isForSale ? (
      //         <>
      //           <NumericFormat
      //             value={info.row.original.listingPrice}
      //             thousandSeparator=" "
      //             displayType={'text'}
      //             prefix={info.row.original.isoCodeCurrency + ' '}
      //           />
      //         </>
      //       ) : (
      //         '-'
      //       )}
      //     </div>
      //   ),
      //   enableSorting: false,
      // }),
      columnHelper.accessor('createdAt', {
        id: 'date',
        header: () => <span className="w-full text-center text-sm">{t('Match Date')}</span>,
        cell: (info) => <div className="w-full text-center">{formatDate(info.getValue()!)}</div>,
      }),
      columnHelper.accessor('percent', {
        id: 'percent',
        header: () => (
          <div className="text-center flex justify-center items-center w-full text-sm  whitespace-nowrap">
            {t('Match Rate')}
          </div>
        ),
        cell: (info) => (
          <div className="justify-center items-center text-sm flex w-full">
            {info.renderValue()}%
          </div>
        ),
      }),
    ];

    const mobileColumns = baseColumns.filter(
      (col: any) => col.id !== 'date' && col.id !== 'rentPrice' && col.id !== 'salePrice'
    ) as ColumnDef<Hunt, any>[];

    return window.innerWidth <= 768 ? mobileColumns : baseColumns;
  }, []);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  return (
    <div>
      <div className="px-1 sm:px-3 pt-3 ring-1 rounded bg-white ring-deehiy">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th className="pb-3" key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none flex items-center'
                            : 'hidden',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <BiChevronUp className="w-5 h-5" />,
                          desc: <BiChevronDown className="w-5 h-5" />,
                        }[header.column.getIsSorted() as string] ?? (
                          <BsChevronExpand className="ml-1 w-4 h-4 text-gray-400" />
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                className="last:border-b-transparent border border-x-transparent border-y-deehiy"
                key={row.id}
              >
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LeadMatchTable;
