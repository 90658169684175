import { io } from 'socket.io-client';
import { baseUrl } from './helper';

const socket = io(`${baseUrl?.replace('/api/', '')}`, {
  path: '/socket.io',
  transports: ['websocket'],
  secure: true,
});

// const baseUrl = 'http://localhost:5000'; // Backend URL
// const socket = io(baseUrl, {
//   path: '/socket.io',
//   transports: ['websocket'],
//   secure: true,
// });

export default socket;
