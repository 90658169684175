import React, { useState, useEffect, useMemo } from 'react';
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
  getSortedRowModel,
} from '@tanstack/react-table';
import type { ColumnDef, SortingState } from '@tanstack/react-table';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { BsChevronExpand } from 'react-icons/bs';
import { useAuth } from '../../../../contexts/auth';
// import { useNavigate } from 'react-router-dom';
import { Hunt } from '../../../../types/types';
import { adjustImageSize } from '../../../../utils/adjustImageSize';
import { NumericFormat } from 'react-number-format';
import {
  updateSubmitMatchService,
  getLeadsByUserIdService,
} from '../../../../services/huntService';
import Loader from '../../../main/Loader';
// import { useTranslation } from 'react-i18next';
import Contact from '../../../main/Contact';

const columnHelper = createColumnHelper<Hunt>();

const LeadTable = () => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [data, setData] = useState<any>(() => []);

  const { currentUser } = useAuth();
  // const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const appToken = localStorage.getItem('@App:token');
  // const navigate = useNavigate();

  const handleChatAndSubmit = async (info: any) => {
    console.log(info);
    const firstName = info?.row?.original?.hunt.user?.firstName;
    const id = info?.row?.original?.hunt.userId;
    const matchId = info?.row?.original?.id;
    console.log(info);

    await updateSubmitMatchService(matchId);

    window.open(
      `${
        process.env.REACT_APP_MESSENGER_URL
      }?token=${appToken}&id=${id}&username=${firstName}&userSpecificChat=${true}`,
      '_blank'
    );
  };

  const columns = useMemo<ColumnDef<Hunt, any>[]>(() => {
    const baseColumns = [
      columnHelper.accessor('huntId', {
        id: 'huntId',
        header: () => <span className="text-xs">Hunt ID</span>,
        cell: (info: any) => <div className="text-center ">#{info.getValue()}</div>,
      }),
      columnHelper.accessor((row) => row.hunter.image, {
        id: 'image',
        header: () => <span className="text-xs  "></span>,
        cell: (info: any) => (
          <img
            src={adjustImageSize(info.renderValue(), 40)}
            alt=""
            className="my-2 sm:my-3 pr-0 max-w-[40px] rounded-full"
          />
        ),
        enableSorting: false,
      }),
      columnHelper.accessor((row) => row.hunter.firstName, {
        id: 'firstName',
        header: () => <span className="text-xs  ">Hunter</span>,
        cell: (info: any) => (
          <div
            title="open profile page"
            className="cursor-pointer line-clamp-2 hover:underline text-xs  items-center justify-start  font-bold flex"
            onClick={() => window.open(`/member/${info.row.original.hunter.id}`, '_blank')}
          >
            {info.getValue()}
          </div>
        ),
        enableSorting: true,
      }),
      columnHelper.accessor('city', {
        id: 'city',
        // header: () => <span>City</span>,
        header: () => <span className="text-xs  ">City</span>,
        cell: (info) => <span className="text-center text-xs">{info.getValue()}</span>,
      }),
      columnHelper.accessor('price', {
        id: 'price',
        header: () => <span className="text-xs  ">Price</span>,
        cell: (info) => {
          return (
            <NumericFormat
              className="text-xs"
              value={info.renderValue()}
              thousandSeparator=" "
              displayType={'text'}
              prefix={info.row.original.isoCodeCurrency + ' '}
            />
          );
        },
      }),

      columnHelper.accessor('isForRent', {
        id: 'type',
        header: () => <span className="text-xs">Rent/Sale</span>,
        cell: (info) => {
          return <div className="text-center text-xs ">{info.getValue() ? 'rent' : 'sale'}</div>;
        },
      }),
      columnHelper.accessor('propertyType', {
        id: 'propertyType',
        header: () => <span className="text-xs  ">Property type</span>,
        cell: (info) => {
          return <span className="text-center text-xs ">{info.getValue()}</span>;
        },
      }),
      columnHelper.accessor('bedrooms', {
        id: 'bedrooms',
        header: () => <span className="text-xs  ">Bedrooms</span>,
        cell: (info) => {
          return <div className="text-center text-xs">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor('date', {
        id: 'date',
        header: () => <span className="text-xs  ">Date</span>,
        cell: (info) => {
          return <div className="text-center text-xs">{info.getValue()}</div>;
        },
      }),
      columnHelper.accessor((row) => row.matches, {
        id: 'matches',
        header: () => <span className="text-xs">Matches</span>,
        cell: (info) => {
          return (
            <div
              title="see the matches"
              onClick={() => window.open(`/users/leads/hunt/${info.row.original.huntId}`, '_blank')}
              className="text-center text-xs font-bold text-deehiy hover:scale-125 hover:underline cursor-pointer"
            >
              {info.getValue()?.length}
            </div>
          );
        },
      }),
      columnHelper.accessor('huntId', {
        header: '',
        cell: (info) => {
          const user = info?.row?.original?.user;

          return <Contact chatButtonHandler={() => handleChatAndSubmit(info)} contact={user} />;
        },
        enableSorting: false,
      }),
    ];

    const mobileColumns = baseColumns.filter(
      (col: any) =>
        col.id !== 'location' &&
        col.id !== 'city' &&
        col.id !== 'image' &&
        col.id !== 'huntId' &&
        col.id !== 'type' &&
        col.id !== 'propertyType' &&
        col.id !== 'bedrooms' &&
        col.id !== 'price'
    ) as ColumnDef<Hunt, any>[];

    return window.innerWidth <= 768 ? mobileColumns : baseColumns;
  }, []);

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    debugTable: true,
  });

  useEffect(() => {
    setIsLoading(true);

    getLeadsByUserIdService(currentUser?.id)
      .then((res) => {
        console.log(res.data);

        setData(() => [...res.data]);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [currentUser]);

  if (data.length === 0) return <>no leads found</>;
  if (isLoading) return <Loader />;

  return (
    <div>
      <div className="px-3 pt-3 ring-1 rounded bg-white ring-deehiy">
        <table className="w-full">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th className="pb-3" key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? 'cursor-pointer select-none flex items-center'
                            : 'hidden',
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(header.column.columnDef.header, header.getContext())}
                        {{
                          asc: <BiChevronUp className="w-5 h-5" />,
                          desc: <BiChevronDown className="w-5 h-5" />,
                          // }[header.column.getIsSorted() as string] ?? null}
                        }[header.column.getIsSorted() as string] ?? (
                          <BsChevronExpand className="ml-1 w-4 h-4 text-gray-400" />
                        )}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => {
              const isSubmitted = row.original.isSubmitted;
              return (
                <tr
                  className={`${
                    isSubmitted ? 'bg-white' : 'bg-white'
                  } last:border-b-transparent border border-x-transparent  border-y-deehiy py-1`}
                  key={row.id}
                >
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LeadTable;
