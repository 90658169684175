import React, { useState } from 'react';
import ChatButton from './ChatButton';

import { MdPhone } from 'react-icons/md';
import Modal2 from '../listing/Modal2';
import { LuContact } from 'react-icons/lu';
import { User } from '../../types/types';

interface ContactProps {
  chatButtonHandler: () => void; // Function type for a button click handler
  contact: User;
}

const Contact = ({ chatButtonHandler, contact }: ContactProps) => {
  const [showPhoneModal, setShowPhoneModal] = useState(false);

  return (
    <div>
      <button
        title="Chat"
        className="primary-btn m-auto text-white rounded-full flex gap-x-2 justify-center items-center w-[100px] sm:w-[130px]"
        onClick={() => setShowPhoneModal(true)}
      >
        <LuContact className="text-white h-5 w-5" /> Contact
      </button>
      {showPhoneModal && (
        <div className="absolute top-0 left-0 w-full h-full">
          <Modal2 showModal={showPhoneModal} setShowModal={setShowPhoneModal}>
            <div className="px-10 pb-10 flex space-y-6 flex-col items-center justify-center">
              <div className="font-bold flex flex-col text-center text-base sm:text-lg">
                Get in touch
              </div>
              <div className="flex justify-center items-center pt-8 gap-x-8">
                <ChatButton chatButtonHandler={chatButtonHandler} />
                {contact?.isPhonePublic && contact?.phone && (
                  <a
                    href={`tel:${contact.phone}`}
                    className="primary-btn m-0 font-bold flex gap-x-2 w-[100px] ring-0 overflow-hidden border-none"
                  >
                    <MdPhone className="text-white h-5 w-5" />
                    Call
                  </a>
                )}
              </div>
            </div>
          </Modal2>
        </div>
      )}
    </div>
  );
};

export default Contact;
