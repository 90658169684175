import React, { FC } from 'react';
import { Helmet } from 'react-helmet';

interface FaviconProps {
  messageCount: number;
}

const updateDocumentTitle = (count: number) => {
  const regex = /^(\(\d+\)\s)(\w*)/;
  const currentTitle = document.title;
  if (regex.test(currentTitle)) {
    const groups = currentTitle.match(regex);
    if (groups?.length) {
      return `(${count}) ${groups[2]}`;
    }
  }
  return `(${count}) ${currentTitle}`;
};

const Favicon: FC<FaviconProps> = ({ messageCount }) => {
  return (
    <Helmet>
      <link
        rel="icon"
        type="image/ico"
        href={messageCount > 0 ? '/favicon-dot.ico' : '/favicon.ico'}
      />
      {(document.title = messageCount > 0 ? updateDocumentTitle(messageCount) : 'deehiy')}
    </Helmet>
  );
};

export default Favicon;
