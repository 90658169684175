import React, { useState, useEffect } from 'react';
import socket from '../../utils/socket';
import { useAuth } from '../../contexts/auth';
import { getUnseenNotificationsCount } from '../../services/notificationService';
import { useNavigate } from 'react-router-dom';
import { FaBell } from 'react-icons/fa';
import { SocketResponse } from '../../App';

const NotificationButton = ({ handleOpenTray }: any) => {
  const [unseenCount, setUnseenCount] = useState(0);
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 768;
  const { currentUser } = useAuth();
  const { id = '' } = currentUser || {};

  const handleClick = () => {
    if (!currentUser) navigate('/login');

    setUnseenCount(0);
    if (isMobile) {
      navigate('/notifications');
    } else {
      handleOpenTray();
    }
  };

  const getSocketUnseenNotificationsCount = (userId: number) => {
    socket.emit('get_unseen_notification_count', { userId }, (ackData: SocketResponse) => {
      const { data } = ackData;
      setUnseenCount(data);
    });
  };

  useEffect(() => {
    // socket.on('new_notification', (notification) => {
    //   alert('new notific');
    //   setNotifications((prev) => [notification, ...prev]);
    //   setUnseenCount((prev) => prev + 1);
    // });
    socket.on('update_unseen_count', (data) => {
      if (data?.unseenCount !== undefined) {
        alert(`from back: ${data.unseenCount}`);
        // Update the notification count in state or context
        setUnseenCount(data.unseenCount); // Assuming you're using React's state
      }
    });

    return () => {
      socket.off('update_unseen_count');
      //   socket.off('new_notification');
    };
  }, []);

  // Fetch notifications from the API on component mount
  useEffect(() => {
    const fetchUnseenNotificationsCount = async () => {
      try {
        const response = await getUnseenNotificationsCount();
        const data = await response.data;
        setUnseenCount(data.unseenCount);
      } catch (error) {
        console.error('Error fetching notifications:', error);
      }
    };

    fetchUnseenNotificationsCount();
  }, []);

  useEffect(() => {
    if (id) {
      const intervalId = setInterval(() => {
        getSocketUnseenNotificationsCount(id);
      }, 3000);
      return () => clearInterval(intervalId);
    }
  }, [id]);

  return (
    <div className="notifications-icon flex items-center justify-center" onClick={handleClick}>
      {isMobile ? (
        <FaBell className="w-7 h-7 sm:w-9 sm:h-9 rounded-full" />
      ) : (
        <img src="/images/icons/notif-green.svg" className="w-7 h-7 sm:w-9 sm:h-9 rounded-full" />
      )}
      {unseenCount > 0 && <span className="red-dot">{unseenCount}</span>}
    </div>
  );
};

export default NotificationButton;
