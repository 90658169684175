import { Hunt } from '../types/types';
import http from './httpService';

const apiHuntEndpoint = '/api/hunts';

export const getAllHuntsService = () => {
  return http.get(`${apiHuntEndpoint}/`);
};

// retrieve 1 single hunt by id
export const getHuntByIdService = (huntId: number) => {
  return http.get(`${apiHuntEndpoint}/${huntId}`);
};

// Update a hunt with id
export const updateHuntService = async (params: any) => {
  const { huntId } = params;
  return http.patch(`${apiHuntEndpoint}/${huntId}`, { hunt: params });
};

// Add a hunt with id
export const createHuntService = async (hunt: Hunt) => {
  return http.post(apiHuntEndpoint, { hunt });
};

// Delete a hunt with id
export const deleteHuntService = (huntId: number) => {
  // will change the status to 'deleted'
  return http.delete(`${apiHuntEndpoint}/${huntId}`);
};

// Search hunt by user id
export const getHuntsByUserIdService = (userId: number) => {
  // return http.get(`${apiHuntEndpoint}/users/${userId}/${currency}`);
  return http.get(`${apiHuntEndpoint}/users/${userId}`);
};

// Search Match by user id
export const getMatchesByUserIdService = (userId: number) => {
  return http.get(`${apiHuntEndpoint}/matches/users/${userId}`);
};

export const getLeadsByUserIdService = (userId: number) => {
  return http.get(`${apiHuntEndpoint}/leads/users/${userId}`);
};

// Update a hunt with id
export const updateSubmitMatchService = async (matchId: number) => {
  return http.patch(`${apiHuntEndpoint}/submitmatch/${matchId}`, { matchId });
};

// SUbmit all matches
export const submitAllMatchesService = async (UserId: number) => {
  return http.patch(`${apiHuntEndpoint}/submitallmatches/${UserId}`, { UserId });
};

// Update a Match with id
export const updateMatchService = async (params: any) => {
  const { matchId } = params;
  return http.patch(`${apiHuntEndpoint}/matches/${matchId}`, { match: params });
};

// get all matches
export const getMatchService = async () => {
  return http.get(`${apiHuntEndpoint}/getmatches/all`);
};

// get properties by huntId
export const getPropertiesByHuntIdService = async (huntId: number) => {
  return http.get(`${apiHuntEndpoint}/properties/${huntId}`);
};

export const fetchMatchesByHuntAndUserId = async (huntId: number) => {
  return http.get(`${apiHuntEndpoint}/matches/${huntId}`);
};

export const extendHuntService = async (params: any) => {
  const { huntId } = params;
  return http.patch(`${apiHuntEndpoint}/${huntId}/extend`, { hunt: params });
};
