import React, { useState, useEffect } from 'react';
// import AdaptiveSidebarLayout from '../../components/square/AdaptiveSidebarLayout';
import OverlaySidebarLayout from '../../components/square/OverlaySidebarLayout';
import Community from '../../components/main/community/Community';
import SEO from '../../components/SEO/SEO';
import CommunityFilter from '../../components/main/filter/CommunityFilter';
// import InterestCarousel from '../../components/main/carousel/InterestsCarousel';
import { COMMUNITY_CATEGORIES } from '../../constants/constant';
import { useGlobal } from '../../contexts/global';
import { useAuth } from '../../contexts/auth';
import NoResults from '../../components/main/NoResults';
// services
import * as communityService from '../../services/communityService';

export const initialCommunityFilter = {
  skills: [],
  interests: [],
  keyword: '', //keyword,
  location: '',
};

const CommunityPage = () => {
  const { currentUser } = useAuth();
  const { keyword } = useGlobal();

  const [dataList, setDataList] = useState<any[]>([]);
  // const [isFilterOpen, setIsFilterOpen] = useState(window.innerWidth >= 768);
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filter, setFilter] = useState(initialCommunityFilter);
  const [tempFilter, setTempFilter] = useState(initialCommunityFilter);

  const [filterState, setFilterState] = useState({
    isWantToSeeOpen: false,
    isSkillsOpen: false,
    isLocationOpen: false,
    isInterestsOpen: false,
  });
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  // const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [count, setCount] = useState(0);

  const [communityCategory, setCommunityCategory] = useState(COMMUNITY_CATEGORIES.ALL);

  // const searchInit = () => {
  //   setHasMore(true);
  //   setPage(1);
  //   setDataList([]);
  // };
  // debounce
  const handleSearch = async (updatedFilter: any, updatedPage: number) => {
    try {
      let res: any;
      switch (communityCategory.value) {
        case COMMUNITY_CATEGORIES.ALL.value:
          res = await communityService.filterAllService(
            updatedFilter,
            updatedPage,
            currentUser?.id
          );
          break;
        case COMMUNITY_CATEGORIES.CONNECTIONS.value:
          res = await communityService.filterContactListService(updatedFilter, updatedPage);
          break;
        case COMMUNITY_CATEGORIES.BLOCKED.value:
          res = await communityService.filterBlockedUsersService(updatedFilter, updatedPage);
          break;
        // Add more cases as needed
        default:
          console.log('Unknown category');
      }

      if (updatedPage === 1) {
        setDataList([...res?.data.users]);
      } else {
        setDataList((list) => [...list, ...res?.data.users]);
      }

      if (res.data.totalPages === updatedPage) {
        setHasMore(false);
      } else {
        setHasMore(true);
        setPage((prevPage) => prevPage + 1);
      }
    } catch (error) {
      console.log('Error while fetching members', error);
    } finally {
    }
  };

  const handleShowResults = () => {
    handleSearch(tempFilter, 1);
    setFilter({ ...tempFilter }); // the temporary filter become the filter for scrolling
  };

  const fetchCount = async (updatedFilter: any) => {
    try {
      const defaultPage = 1;
      let res: any;
      switch (communityCategory.value) {
        case COMMUNITY_CATEGORIES.ALL.value:
          res = await communityService.filterAllService(
            updatedFilter,
            defaultPage,
            currentUser?.id
          );
          break;
        case COMMUNITY_CATEGORIES.CONNECTIONS.value:
          res = await communityService.filterContactListService(updatedFilter, defaultPage);
          break;
        case COMMUNITY_CATEGORIES.BLOCKED.value:
          res = await communityService.filterBlockedUsersService(updatedFilter, defaultPage);
          break;
        // Add more cases as needed
        default:
          console.log('Unknown category');
      }

      setCount(res.data.totalCount);
    } catch (error) {
      console.log('no count found', error);
    } finally {
    }
  };

  const resetFilter = () => {
    setFilter({ ...initialCommunityFilter, keyword });
    setCommunityCategory(COMMUNITY_CATEGORIES.ALL);
  };

  const toggleDropdown = (dropdownKey: keyof typeof filterState) => {
    setFilterState((prevState) => {
      // Create a new state object with all dropdowns set to false
      const newState = {
        isWantToSeeOpen: false,
        isSkillsOpen: false,
        isLocationOpen: false,
        isInterestsOpen: false,
      };

      // Set the specified dropdown to true
      newState[dropdownKey] = !prevState[dropdownKey];

      return newState;
    });
  };

  // initial search
  useEffect(() => {
    // handleSearch({ ...tempFilter, keyword }, 1);
    return () => resetFilter();
  }, []);

  useEffect(() => {
    // Use refs to capture the latest state values
    const currentKeyword = keyword;
    const currentTempFilter = { ...tempFilter, keyword: currentKeyword };

    const delayDebounceFn = setTimeout(() => {
      // Use the latest filter object here
      setFilter((prev) => ({ ...prev, keyword: currentKeyword }));
      setTempFilter(currentTempFilter);
      handleSearch(currentTempFilter, 1); // Use the updated filter object
    }, 300); // 300ms delay, adjust as necessary

    return () => clearTimeout(delayDebounceFn); // Cleanup the timeout if the keyword changes again
  }, [keyword]);

  useEffect(() => {
    fetchCount(tempFilter);
  }, [tempFilter, communityCategory]);

  if (dataList.length === 0) return <NoResults />;

  return (
    <>
      <SEO
        title="Easiest way to buy and sell property - Deehiy"
        description="Buy and sell property with ease globally and take control of your real estate business"
        name="Deehiy"
        type="Community page"
        image={`${process.env.REACT_APP_BASE_URL_PROD}/images/meta-image.jpeg`}
      />
      {/* top filter carrousel */}
      {/* <div
        className="w-full top-2 sm:top-16 z-10 sm:px-20 pb-1 flex px-6 bg-[#f7f7f7] mx-auto sticky 
        shadow-md border border-transparent border-b-deehiy sm:border-none"
      >
        <InterestCarousel searchParams={searchParams} setSearchParams={setSearchParams} />
      </div> */}
      {/* <AdaptiveSidebarLayout
        Filter={() => (
          <CommunityFilter
            communityCategory={communityCategory}
            setCommunityCategory={setCommunityCategory}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
            filterState={filterState}
            setFilterState={setFilterState}
          />
        )}
      >
        <Community
          membersList={dataList}
          hasMore={hasMore}
          handleSearch={handleSearch}
          page={page}
        />
      </AdaptiveSidebarLayout> */}

      <OverlaySidebarLayout
        isFilterOpen={isFilterOpen}
        setIsFilterOpen={setIsFilterOpen}
        // setIsFilterOpen={setIsFilterOpen}
        communityCategory={communityCategory}
        setCommunityCategory={setCommunityCategory}
        tempFilter={tempFilter}
        setTempFilter={setTempFilter}
        filterState={filterState}
        setFilterState={setFilterState}
        resetFilter={resetFilter}
        handleSearch={handleShowResults}
        toggleDropdown={toggleDropdown}
        count={count}
        Filter={CommunityFilter}
        isFullWidth={false}
      >
        <Community
          membersList={dataList}
          hasMore={hasMore}
          handleSearch={() => handleSearch(filter, page)}
        ></Community>{' '}
      </OverlaySidebarLayout>
    </>
  );
};

export default CommunityPage;
