import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { extendHuntService } from '../../services/huntService';
import Loader from '../main/Loader';

const ExtendHuntPage = () => {
  const [status, setStatus] = useState('loading'); // 'loading', 'success', 'error'
  const [message, setMessage] = useState<any>('');
  const navigate = useNavigate();
  const { huntId } = useParams();
  const location = useLocation(); // Get query string from the location object

  useEffect(() => {
    const processToken = async () => {
      const params = new URLSearchParams(location.search);
      const token = params.get('token');

      if (!huntId || !token) {
        setStatus('error');
        setMessage('Invalid or missing hunt ID or token.');
        return;
      }

      try {
        await extendHuntService({ huntId, token });
        setStatus('success');
        setMessage('Hunt extended successfully!');
      } catch (error: any) {
        setStatus('error');
        setMessage(error.response?.data?.error || 'An error occurred.');
      }
    };

    processToken();
  }, [huntId, location.search]);

  if (status === 'loading') {
    return <Loader />;
  }

  return (
    <div className="container text-center mx-auto flex flex-col pt-16 space-y-12">
      {status === 'success' && <h1>{message}</h1>}
      {status === 'error' && (
        <>
          <h1>Something went wrong</h1>
          <p>{message}</p>
        </>
      )}
      <div className="mx-auto w-[360px] sm:w-[500px] flex justify-between ">
        <button onClick={() => navigate('/')} className="primary-btn">
          Back to Home
        </button>
        <button onClick={() => navigate('/users/dashboard')} className="secondary-btn">
          Go to Dashboard
        </button>
      </div>
    </div>
  );
};

export default ExtendHuntPage;
