import React, { useState, useEffect, useRef } from 'react';
// import socket from '../../utils/socket';
// import { Notification } from '../../types/types';
// import {
//   getNotificationsByUserId,
//   markAllNotificationsAsSeen,
// } from '../../services/notificationService';
// import { SocketResponse } from '../../App';
import NotificationButton from './NotificationButton';
import NotificationContent from './NotificationContent';

const NotificationsTray = () => {
  // const [notifications, setNotifications] = useState<Notification[]>([]); // Notifications list
  // const [unseenCount, setUnseenCount] = useState(0); // Number of unseen notifications
  const [isOpen, setIsOpen] = useState(false); // Tray open/close state
  const divRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (buttonRef.current && buttonRef.current.contains(event.target as Node)) {
      return;
    }
    if (
      divRef.current &&
      event.target instanceof Node && // Ensure event.target is a Node
      !divRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };
  // const getUnseenNotificationsCount = (userId: number) => {
  //   socket.emit('get_unseen_notification_count', { userId }, (ackData: SocketResponse) => {
  //     const { data } = ackData;
  //     if (data > 0) {
  //       setUnseenCount(data);
  //     }
  //   });
  // };
  // const handleNotificationTrayOpen = () => {
  //   socket.emit(
  //     'mark_notifications_seen',
  //     { userId: currentUser?.id },
  //     (response: SocketResponse) => {
  //       if (response.success) {
  //         setNotificationCount(0); // Reset unseen count
  //         setShowRedDot(false); // Hide red dot
  //       }
  //     }
  //   );
  // };
  // Socket connection
  //useEffect(() => {
  // Receive real-time notification updates
  //socket.on('new_notification', (notification) => {
  //alert('new notific');
  // setNotifications((prev) => [notification, ...prev]);
  // setUnseenCount((prev) => prev + 1);
  //});
  // socket.on('update_unseen_count', (data) => {
  //   if (data?.unseenCount !== undefined) {
  //     alert(`from back: ${data.unseenCount}`);
  //     setUnseenCount(data.unseenCount); // Assuming you're using React's state
  //   }
  // });

  //return () => {
  // socket.off('update_unseen_count');
  // socket.off('new_notification');
  // };
  //}, []);

  const handleOpenTray = async () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="notifications-tray">
      <div ref={buttonRef}>
        <NotificationButton handleOpenTray={handleOpenTray} />
      </div>

      {isOpen && (
        <div ref={divRef} className="tray-content scroller pb-6">
          <NotificationContent />
        </div>
      )}
    </div>
  );
};

export default NotificationsTray;
