import http from './httpService';

const notificationEndpoint = '/api/notifications';

// Retrieve notifications for a user
export const getUnseenNotificationsCount = () => {
  return http.get(`${notificationEndpoint}/unseen-count`);
};

export const getNotificationsByUserId = (page?: number, isRead?: boolean) => {
  let query = `${notificationEndpoint}?page=${page}`;

  if (isRead !== undefined) {
    query += `&isRead=${isRead}`;
  }

  return http.get(query);
};

// Mark a single notification as read/unread
export const toggleNotificationAsRead = (notificationId: number, isRead: boolean) => {
  return http.patch(`${notificationEndpoint}/${notificationId}/read`, { isRead });
};

// Mark all notifications as read
export const markAllNotificationsAsRead = (userId: number) => {
  return http.patch(`${notificationEndpoint}/read-all`, { userId });
};

// Mark all notifications as seen
export const markAllNotificationsAsSeen = () => {
  return http.patch(`${notificationEndpoint}/seen-all`);
};

export const deleteNotification = (notificationId: number) => {
  return http.delete(`${notificationEndpoint}/${notificationId}`);
};
