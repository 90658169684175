import React, { useState, useEffect } from 'react';
import socket from '../../utils/socket';
import { Notification } from '../../types/types';
import { timeAgo } from '../../utils/dateFormatter';
import { CustomDropwdown, DropdownItem } from './navigation/Navigation';
import { adjustImageSize } from '../../utils/adjustImageSize';
import { DropdownDotsBtn } from './community/Community';
import DOMPurify from 'dompurify';
import {
  getNotificationsByUserId,
  markAllNotificationsAsSeen,
  toggleNotificationAsRead,
  deleteNotification,
} from '../../services/notificationService';
import Loader from './Loader';
// import { SocketResponse } from '../../App';
import { notificationTemplates } from '../../data/data';
import { useNavigate } from 'react-router-dom';

function NotificationItem({
  notification,
  setNotifications,
}: {
  notification: Notification;
  setNotifications: React.Dispatch<React.SetStateAction<Notification[]>>;
}) {
  const navigate = useNavigate();
  const typedNotification = notificationTemplates[notification.typeId];

  const imageURL = typedNotification.svg
    ? typedNotification.svg
    : adjustImageSize(notification.metadata.imageUrl);

  const propertyId = notification.metadata.propertyId || '';
  const firstName = notification.metadata.firstName || '';
  // const userId = notification.metadata.userId || '';
  const postId = notification.metadata.postId || '';

  const toggleReadStatus = async (status: boolean) => {
    try {
      // Call the API to toggle the notification as read
      await toggleNotificationAsRead(notification.id, status);

      // Update the state to mark the specific notification(s) as read
      setNotifications((prevNotifications) =>
        prevNotifications.map((notif) =>
          notif.id === notification.id ? { ...notif, isRead: status } : notif
        )
      );
    } catch (error) {
      console.error('Failed to mark notification as read:', error);
    }
  };

  const handleDeleteNotification = async () => {
    try {
      // Call the API to toggle the notification as read
      await deleteNotification(notification.id);

      // Update the state to mark the specific notification(s) as read
      setNotifications((prevNotifications) =>
        prevNotifications.filter((notif) => notif.id !== notification.id)
      );
    } catch (error) {
      console.error('Failed to delete notification', error);
    }
  };

  const handleClick = async () => {
    if (!notification.isRead) {
      toggleReadStatus(true);
    }

    switch (notification.typeId) {
      case 1:
        if (typedNotification && typedNotification.action) {
          navigate(typedNotification.action.route);
        }
        break;
      case 2:
        if (typedNotification && typedNotification.action) {
          navigate(typedNotification.action.route);
        }
        break;
      case 3:
        navigate('/');
        break;
      case 4:
        navigate(`/properties/${propertyId}`);
        break;
      case 5:
        navigate(`/properties/${propertyId}`);
        break;
      case 6:
        navigate(`/square/post/${postId}`);
        break;
      case 7:
        navigate(`/square/post/${postId}`);
        break;
      case 8:
        navigate(`/square/post/${postId}`);
        break;
      default:
        break;
    }
  };

  const text = () => {
    switch (notification.typeId) {
      case 1:
        return 'You’ve got new leads for listing. Be the first to contact them!';
      case 2:
        return `You’ve got a new match for your hunt. Contact the listing owner now!`;
      case 3:
        return `{{userName}} posted a listing in your area.`;
      case 4:
        return `<span>Your listing <strong>${propertyId}</strong> has been published.</span>`;
      case 5:
        return `<span><strong>${firstName}</strong> loves your listing <strong>${propertyId}</strong></span>`;
      case 6:
        return `<span><strong>${firstName}</strong> loves your post.</span>`;
      case 7:
        return `<span><strong>${firstName}</strong> commented on your post.</span>`;
      case 8:
        return `<span><strong>${firstName}</strong> loves your comment.</span>`;
      default:
        return 'notification error';
        break;
    }
  };

  const sanitizedHTML = DOMPurify.sanitize(text());

  return (
    <div className="relative">
      <div className="absolute top-2 right-2">
        <CustomDropwdown Btn={<DropdownDotsBtn />}>
          <div className="space-y-5 px-8 sm:px-0 sm:space-y-0">
            {notification.isRead ? (
              <DropdownItem
                itemLabel={'mark as unread'}
                onClick={() => {
                  toggleReadStatus(false);
                }}
              />
            ) : (
              <DropdownItem
                itemLabel={'mark as read'}
                onClick={() => {
                  toggleReadStatus(true);
                }}
              />
            )}

            <DropdownItem
              itemLabel={'delete'}
              // imageUrl="/images/icons/post-chat.svg"
              onClick={handleDeleteNotification}
            />
          </div>
        </CustomDropwdown>
      </div>
      <div
        title="Open"
        onClick={handleClick}
        className={` h-20 px-1 group items-center justify-center flex cursor-pointer hover:bg-gray-100 ${
          notification.isRead ? 'text-gray-600 bg-white ' : 'bg-deehiy-veryLight'
        }`}
      >
        <div className="flex space-x-2 w-full">
          <div className="relative flex w-1/5 items-center justify-center">
            <img src={imageURL} className=" rounded-full w-16 h-16" />
            <div className="absolute w-5 h-5 bottom-0 right-0 rounded-full flex justify-center items-center bg-white p-[2px] border-2 border-deehiy">
              {typedNotification?.icon && <typedNotification.icon className="text-deehiy" />}{' '}
            </div>
          </div>
          <div className="text-sm w-4/5 flex flex-col pr-2">
            <div className="flex w-full justify-between">
              <div className="w-full flex justify-start text-gray-400">
                {timeAgo(notification.createdAt)}
              </div>
            </div>
            <div>
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitizedHTML,
                }}
                className="inline"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const NotificationContent = () => {
  const [notifications, setNotifications] = useState<Notification[]>([]); // Notifications list
  // const [unseenCount, setUnseenCount] = useState(0); // Number of unseen notifications
  const [pageIndex, setPageIndex] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isRead, setIsRead] = useState<boolean>(true);

  const fetchNotifications = async (isReadFilter: any, reset = false) => {
    try {
      setIsLoading(true);
      const page = reset ? 1 : pageIndex;

      const res = await getNotificationsByUserId(page, isReadFilter ? undefined : false);

      if (reset) {
        setNotifications(res.data.notifications);
        setPageIndex(page + 1);
      } else {
        setNotifications((prev) => [...prev, ...res.data.notifications]);
        setPageIndex((prev) => prev + 1);
      }

      setHasMore(res.data.hasMore);
    } catch (error) {
      console.error('Error fetching notifications:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFetchMore = async () => {
    if (hasMore) {
      await fetchNotifications(isRead);
    }
  };

  useEffect(() => {
    const markSeen = async () => {
      try {
        await markAllNotificationsAsSeen();
      } catch (error) {
        console.error('Error marking notifications as seen:', error);
      }
    };
    markSeen();
  }, []);

  useEffect(() => {
    // Reset notifications and fetch for the selected filter
    fetchNotifications(isRead, true);
  }, [isRead]);

  useEffect(() => {
    // Receive real-time notification updates
    socket.on('new_notification', (notification) => {
      alert('new notific');
      setNotifications((prev) => [notification, ...prev]);
      // setUnseenCount((prev) => prev + 1);
    });
    // socket.on('update_unseen_count', (data) => {
    //   if (data?.unseenCount !== undefined) {
    //     alert(`from back: ${data.unseenCount}`);
    //     setUnseenCount(data.unseenCount); // Assuming you're using React's state
    //   }
    // });

    return () => {
      // socket.off('update_unseen_count');
      socket.off('new_notification');
    };
  }, []);

  return (
    <div>
      <h3>Notifications</h3>
      <div className="flex px-4 py-2 space-x-8 text-sm font-bold">
        <button
          className={`px-2 py-1 rounded-xl ${
            isRead ? 'bg-deehiy-veryLight text-deehiy' : 'bg-gray-100'
          }`}
          onClick={() => setIsRead(true)}
        >
          All
        </button>
        <button
          className={`px-2 py-1 rounded-xl ${
            !isRead ? 'bg-deehiy-veryLight text-deehiy' : 'bg-gray-100 '
          }`}
          onClick={() => setIsRead(false)}
        >
          Unread
        </button>
      </div>
      {notifications.length > 0 ? (
        <>
          <ul>
            {notifications.map((notification: Notification) => (
              <NotificationItem
                key={notification.id}
                notification={notification}
                setNotifications={setNotifications}
              />
            ))}
          </ul>
          <div className="w-full flex items-center justify-center h-auto pt-3 pb-12 sm:pb-1">
            {hasMore ? (
              <button
                className="rounded-lg text-sm w-5/6 bg-gray-100 mx-2 my-auto shadow-sm p-1 hover:bg-gray-200 semibold"
                onClick={handleFetchMore}
              >
                see previous notifications
              </button>
            ) : (
              'no more notifications'
            )}
          </div>
          {isLoading && <Loader />}
        </>
      ) : (
        <p className="px-2">No notifications</p>
      )}
    </div>
  );
};

export default NotificationContent;
