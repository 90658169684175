import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { AiOutlineClose } from 'react-icons/ai';
import { useGlobal } from '../../../contexts/global';

const ModalBody = ({ children, closeModal }: any) => {
  return (
    <div className="flex flex-col w-full h-full z-50 rounded-t-3xl text-xs bg-white">
      <div className="flex justify-start">
        <button className="text-deehiy pt-4 px-3" onClick={closeModal}>
          <AiOutlineClose className="h-6 w-6" />
        </button>
      </div>
      <div className="pt-6">{children}</div>
    </div>
  );
};

const MobileModal = ({ isModalOpen, setIsModalOpen, children }: any) => {
  const { modalRootRef } = useGlobal();
  const [visible, setVisible] = useState(false); // State to control animation visibility

  const [maxHeight, setMaxHeight] = useState(0);

  const closeModal = () => {
    // Set visible to false for smooth animation out
    setVisible(false);
    setIsModalOpen(false);
  };

  useEffect(() => {
    const handleEscapeKey = (event: any) => {
      if (event.key === 'Escape') {
        closeModal();
      }
    };

    window.addEventListener('keydown', handleEscapeKey);

    return () => {
      window.removeEventListener('keydown', handleEscapeKey);
    };
  }, []);

  // Dynamically calculate the max-height based on the content when the modal becomes visible
  useEffect(() => {
    if (visible) {
      const modalContent = document.querySelector('#modalContent'); // Select the modal content by ID
      const height = modalContent?.scrollHeight || 0;
      // Set height to be no more than 90vh
      setMaxHeight(Math.min(height, window.innerHeight * 0.9)); // Ensure max-height doesn't exceed 90vh
    }
  }, [visible]);

  useEffect(() => {
    // Update visible state to match isModalOpen
    setVisible(isModalOpen);
  }, [isModalOpen]);

  if (!isModalOpen || !modalRootRef.current) return null;

  return (
    <>
      {isModalOpen && modalRootRef.current
        ? ReactDOM.createPortal(
            <div className="mt-auto w-full bottom-0 fixed z-50">
              <div
                className={
                  'fixed overflow-hidden z-40 bg-gray-900 bg-opacity-30 inset-0 transform ease-in-out ' +
                  (visible
                    ? ' transition-opacity opacity-100 duration-200 translate-y-0  '
                    : ' transition-opacity opacity-0 duration-200 translate-y-full ')
                }
              >
                <div
                  id="modalContent" // Added ID for dynamic height calculation
                  className={
                    'rounded-t-2xl absolute bottom-0 w-full shadow-xl transform transition-all duration-500 ease-in-out ' +
                    (visible ? 'max-h-[90vh]' : 'max-h-0') // Tailwind handles max height logic with a cap of 90vh
                  }
                  style={{
                    maxHeight: visible ? `${maxHeight}px` : '0', // Dynamically calculate the maxHeight
                  }}
                >
                  <ModalBody children={children} closeModal={closeModal} />
                  <div className="h-20 bg-white"></div>
                </div>
                <div
                  className=" w-screen h-full cursor-pointer "
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                ></div>
              </div>
            </div>,
            modalRootRef.current
          )
        : null}
    </>
  );
};

export default MobileModal;
