import http from './httpService';
import { Property, Status } from '../types/types';
const apiAdminEndpoint = '/api/admin';

// Admin: Retrieve all Properties
export const adminGetAllProperties = (currency: string) => {
  const token = localStorage.getItem('@App:token');

  return http.get(`${apiAdminEndpoint}/properties/${currency}`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

// Admin: Retrieve all the users
export const adminGetAllUsers = () => {
  const token = localStorage.getItem('@App:token');

  return http.get(`${apiAdminEndpoint}/users`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

// Admin: Retrieve all Properties
export const adminGetAllBusinesses = () => {
  const token = localStorage.getItem('@App:token');

  return http.get(`${apiAdminEndpoint}/businesses/`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

// Admin: Retrieve all the Matches
export const adminGetAllMatches = () => {
  const token = localStorage.getItem('@App:token');

  return http.get(`${apiAdminEndpoint}/matches`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

// Admin: Retrieve all the Hunts
export const adminGetAllHunts = () => {
  const token = localStorage.getItem('@App:token');

  return http.get(`${apiAdminEndpoint}/hunts`, {
    headers: {
      Authorization: `${token}`,
    },
  });
};

// Admin: Update a Property with id
export const adminUpdateProperty = async (property: Property) => {
  const token = localStorage.getItem('@App:token');

  const { propertyId } = property;

  return http.put(
    `${apiAdminEndpoint}/property/${propertyId}`,
    { property },
    {
      headers: {
        'x-auth-token': `${token}`,
      },
    }
  );
};

// Admin: Update a Property with id
export const adminSendStatusEmail = async (propertyId: number, status: Status, message: string) => {
  const token = localStorage.getItem('@App:token');

  return http.post(
    `${apiAdminEndpoint}/emailstatus`,
    { status, message, propertyId },
    {
      headers: {
        Authorization: `${token}`,
      },
    }
  );
};

// Admin: change publish/unpublish property and send notification
export const adminChangePropertyPublishStatus = (propertyId: number, status: string) => {
  return http.post(`${apiAdminEndpoint}/publish-status/`, { propertyId, status });
};

// Admin: toggle publish/unpublish property
export const adminTogglePropertyPublishStatus = (propertyId: number) => {
  return http.post(`${apiAdminEndpoint}/publish/`, { propertyId });
};

// Admin: Delete a Property with id
export const adminDeleteProperty = (propertyId: number) => {
  // will change the status to 'deleted'
  return http.post(`${apiAdminEndpoint}/delete`, { propertyId });
};
