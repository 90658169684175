import React, { useState, useEffect } from 'react';
// import { useHunt } from '../../../contexts/hunt';
// import { useAuth } from '../../../contexts/auth';
import { useParams, useNavigate, Link } from 'react-router-dom';
import LeadMatchTable from './table/LeadMatchTable';
import Loader from '../../main/Loader';
import TakeMeBack from '../../main/navigation/TakeMeBack';
import { useTranslation } from 'react-i18next';
import { BiChevronRight } from 'react-icons/bi';
// import { initialHunt } from '../../../initialStates/initialStates';
import { fetchMatchesByHuntAndUserId } from '../../../services/huntService';
import Contact from '../../main/Contact';
import { NumericFormat } from 'react-number-format';
import { Avatar } from '../../../pages/ListingPage';

const ListMatchesPerHunt = () => {
  const { id } = useParams();
  // const { fetchHuntByIdContext } = useHunt();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [matches, setMaches] = useState([]);
  const [hunter, setHunter] = useState<any>({});
  const [hunt, setHunt] = useState<any>({});
  const appToken = localStorage.getItem('@App:token');

  const chatHandler = async (user: any) => {
    const firstName = user.firstName;
    const hunterId = user.id;

    window.open(
      `${
        process.env.REACT_APP_MESSENGER_URL
      }?token=${appToken}&id=${hunterId}&username=${firstName}&userSpecificChat=${true}`,
      '_blank'
    );
  };

  const fetchMatches = async (huntId: any) => {
    setIsLoading(true);
    fetchMatchesByHuntAndUserId(Number(huntId))
      .then((fetched: any) => {
        console.log(fetched);
        if (!fetched.data.hunt.huntId) {
          alert('you are not allowed to access this hunt');
          // navigate('/');
        } else {
          console.log(fetched.id);
          setMaches(fetched.data.matches);
          setHunter(fetched.data.hunter);
          setHunt(fetched.data.hunt);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (id) {
      // authentication check
      fetchMatches(id);
    }
  }, [id]);

  if (isLoading) return <Loader />;

  if (!matches)
    return (
      <div className="container items-center justify-center mx-auto h-screen">
        <div className="py-4 sm:py-12 space-y-6 sm:space-y-12 ">
          <div className="text-sm font-bold sm:text-lg pt-2 sm:pt-4 text-center">
            No Match Yet — But We're On It
          </div>
          <button
            className="primary-btn flex items-center justify-center"
            onClick={() => navigate('/users/dashboard')}
          >
            <span>Go to dashboard</span>
            <BiChevronRight className="w-5 h-5" />
          </button>
        </div>
      </div>
    );
  return (
    <div className="container mx-auto h-screen">
      <div className="py-4 sm:py-12 space-y-6 sm:space-y-12 ">
        <TakeMeBack loc={'/users/huntingboard'} />
        <div className="text-xl sm:text-2xl font-bold w-full ">
          You have {matches?.length || 0} listing{matches?.length && matches?.length > 1 && 's'} for
          this hunt.{' '}
        </div>

        <div className="sm:flex-row flex-col flex justify-around ">
          <div className="flex w-80 mx-auto sm:mx-0 bg-white shadow-lg flex-col border border-deehiy rounded-lg space-y-4 p-6 text-sm">
            <div className="text-base text-gray-400 font-bold border-b-gray-200 border-transparent border pb-1">
              Hunt{' '}
            </div>
            <div className="justify-between flex">
              <b>Hunt ID: </b>
              <span>#{hunt.huntId}</span>
            </div>
            <div className="justify-between flex">
              <b>Location: </b>
              <span>{hunt.city}</span>
            </div>
            <div className="justify-between flex">
              <b>Bedrooms: </b>
              <span>{hunt.bedrooms}</span>
            </div>
            <div className="justify-between flex">
              <b>Property type: </b>
              <span>{hunt.propertyType}</span>
            </div>
            <div className="justify-between flex">
              <b>Rent/Buy: </b>
              <span>{hunt.isForRent ? 'rent' : 'buy'}</span>
            </div>
            <div className="justify-between flex">
              <b>Budget: </b>
              <span>
                <NumericFormat
                  value={hunt.price}
                  thousandSeparator=" "
                  displayType={'text'}
                  prefix={hunt.isoCodeCurrency + ' '}
                />
              </span>
            </div>
          </div>
          <div className="flex w-80 mt-2 sm:mt-0 mx-auto sm:mx-0 bg-white shadow-lg flex-col border border-deehiy rounded-lg space-y-8 p-6 text-xl font-bold">
            <div className="text-base text-gray-400 font-bold border-b-gray-200 border-transparent border pb-1">
              Hunter
            </div>
            <div className="flex space-x-6 justify-center items-center">
              <Link to={`/member/${hunter.id}`}>
                <div className="hidden sm:flex">
                  <Avatar isSales={hunter?.isSalesRep} image={hunter?.image} size={20} />
                </div>
                <span className="font-semibold mx-auto">
                  {hunter.firstName} {hunter.lastName}
                </span>
              </Link>
            </div>
            <Contact chatButtonHandler={() => chatHandler(hunter)} contact={hunter} />
          </div>
        </div>

        <div className="text-base sm:text-xl font-bold w-full ">
          {t('Review these listings and reach out to the hunter')}
        </div>
      </div>
      <LeadMatchTable matches={matches} />
      {/* matches={currentHunt.matches} */}
      <div className="pb-6"></div>
    </div>
  );
};

export default ListMatchesPerHunt;
